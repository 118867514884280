import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
// import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
// import HeightIcon from "@material-ui/icons/Height";
import Status from "./common/Status";
import { formatPrice } from "../util";

const S = {
  Card: styled.div`
    overflow: hidden;
    margin-top: 3rem;
    margin-bottom: 3rem;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0 23px 49px -9px rgba(0, 0, 0, 0.16);
    width: 30%;
    max-width: 25rem;
    min-width: 23rem;
    margin-top: 5rem;
    margin-bottom: 0rem;

    @media screen and (min-width: 1920px) {
      width: 30%;
      max-width: 30rem;
    }
    @media screen and (min-width: 1440px) {
      margin-top: 3rem;
    }
    @media screen and (max-width: 991px) {
      min-width: auto !important;
      width: 48%;
    }
    @media screen and (max-width: 767px) {
      min-width: auto !important;
      width: 100%;
    }
    @media screen and (max-width: 500px) {
      width: 100%;
      min-width: auto!important;
      max-width: 100%;
    }
  `,
  CardContent: styled.div`
    position: relative;
    padding: 0px;
  `,
  DetailsHeader: styled.h2`
    font-weight: 500;
    font-size: 20px;
  `,
  ViewBtn: styled.input`
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0 30px;
    color: #c7c7c7;
    border-radius: 30px;
    border: 1px solid #e9e9e9;
    background: white;
    margin: 10px 0px;
    cursor: pointer;
    font-size: 12px;
  `,
  IconsContainer: styled.div`
    display: flex;

    div {
      display: flex;
      align-items: center;
      svg {
        height: 17px;
      }
    }
  `,
  Typography: styled(Typography)`
    display: flex;
    justify-content: space-between;

    sup {
      vertical-align: super;
      font-size: xx-small;
    }
  `,
  PdfFile: styled.div`
    min-height: 24px;
    max-height: 24px;
    a {
      display: flex;
      align-items: center;
      svg {
        font-size: 1rem;
        margin-right: 3px;
      }
      &:hover {
        font-weight: 800;
      }
    }
  `,
  CardMediaWrapper: styled.div`
    position: relative;
    overflow: hidden;
    height: 13.5rem;
    border-radius: 10px 10px 0px 0px;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: 50% 50%;
    object-position: 50% 50%;
  `,
  CardMedia: styled(CardMedia)`
    &&& {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: 50% 50%;
      object-position: 50% 50%;
    }

    ${({ height }) => css`
      height: ${height};
    `};
  `,
  FavouriteBtn: styled.div`
    position: absolute;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    margin-top: 1rem;
    margin-right: 1.25rem;
    padding-top: 1px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 20px;
    background-color: #fff;
    cursor: pointer;
    img {
      width: 1rem;
      height: 1rem;
      max-width: 100%;
      vertical-align: middle;
      display: inline-block;
      border: 0;
    }
  `,
  BuilderLogo: styled.img`
    position: absolute;
    left: 0%;
    top: 0%;
    right: auto;
    bottom: auto;
    width: 6rem;
    margin-top: 1rem;
    margin-left: 1.25rem;
  `,
  DetailsSection: styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #000;
    margin-bottom: 10px;
    ${({ reduceMargin }) =>
      reduceMargin &&
      css`
        margin-bottom: 0px;
      `}
  `,
  Title: styled.div`
    font-size: 1.125rem;
    font-weight: bold;
    color: #000;
  `,
  Price: styled.div`
    font-size: 1.125rem;
    font-weight: bold;
    color: #000;
  `,
  Status: styled(Status)`
    width: 8px;
    height: 8px;
  `,
  LotButton: styled.div`
    position: static;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 6rem;
    height: 100%;
    margin-top: 0rem;
    margin-bottom: 1rem;
    padding: 0.4rem 0.75rem;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    border-style: solid;
    border-width: 1px;
    border-color: #c9dce6;
    border-radius: 100px;
    text-align: right;
  `,
  Enquire: styled.a`
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    width: auto;
    height: auto;
    margin: 1rem 2rem 1.5rem;
    padding: 0.3rem 1.1rem 0.3rem 0.7rem;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-radius: 100px;
    background-color: #53addb;
    color: #fff;
    text-align: center;
    text-decoration: none;
    -o-object-fit: fill;
    object-fit: fill;

    div {
      font-family: Karla, sans-serif;
      font-weight: 700;
      text-align: center;
    }
  `,

  CardMainContent: styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    margin-top: 1.5rem;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  `,
  CardMainContentLeft: styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;

    .size {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 1rem;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      font-family: Karla, sans-serif;

      .img-wrapper {
        margin-right: 0.5rem;
        padding: 0.25rem 0.4rem;
        border-radius: 100px;
        background-color: rgba(233, 241, 252, 0.6);
        &.house {
          margin-right: 0.5rem;
          padding: 0.2rem 0.3rem;
          border-radius: 100px;
          background-color: rgba(233, 241, 252, 0.6);
        }
        img {
          &.houseIcon {
            width: 0.9rem;
            height: auto;
            margin-right: 0rem;
            opacity: 1;
          }
          &.areaIcon {
            width: 0.6rem;
            height: auto;
            margin-right: 0rem;
            opacity: 1;
          }
          &.frontageIcon {
            width: 0.7rem;
            height: auto;
            margin-right: 0rem;
            opacity: 1;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
        &.showcase {
          padding: 1px 4px;
          margin-left: 1px;
          img {
            width: 0.8rem !important;
            height: 0.7rem !important;
            margin-right: 0rem;
          }
        }        
      }

      div {
        font-family: Karla, sans-serif;
        font-size: 0.85rem;
        font-weight: 300;
        span {
          color: #a5a2a2;
        }
      }
    }

    h2 {
      margin-top: 0px;
      margin-bottom: 0.5rem;
      font-family: alverata, sans-serif;
      font-size: 1.5rem;
      line-height: 1;
      font-weight: 700;
    }

    h3 {
      margin-top: 0rem;
      margin-bottom: 1.5rem;
      font-family: Karla, sans-serif;
      color: #a5a2a2;
      font-size: 14px;
      line-height: 1;
      font-weight: 400;
    }
  `,
  CardMainContentAmenities: styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 8.6rem;
    margin-top: 0.3rem;
    margin-bottom: 1.2rem;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

    .amenities-item {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 2.2rem;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
      -ms-flex-align: end;
      align-items: flex-end;

      img {
        width: 1.5rem;
        height: 1.5rem;
      }

      div {
        font-family: "Sofia pro", Arial, sans-serif;
        color: #4c4949;
        font-size: 0.85rem;
        font-weight: 400;
      }
    }
  `,
  BrochureWrapper: styled.div`
    font-family: "Sofia pro", Arial, sans-serif;
    position: absolute;
    left: 0%;
    top: 0%;
    right: auto;
    bottom: auto;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    padding: 0.25rem 0.5rem;
    border-radius: 30px;
    background-color: #fff;
    font-weight: 300;
    cursor: pointer;

    a {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-family: "Sofia pro", Arial, sans-serif;
      color: #404040;
      font-weight: 300;
      text-decoration: none;
      margin-top: 2px;

      img {
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
        opacity: 0.62;
      }

      div {
        -webkit-font-smoothing: antialiased;
        font-family: "Sofia pro", Arial, sans-serif;
        color: #4c4949;
        font-size: 0.85rem;
        font-weight: 400;
      }
    }
  `,
  CardMainContentRight: styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    text-align: right;

    h3.price {
      margin-top: 0.3rem;
      margin-bottom: 0.3rem;
      font-family: Karla, sans-serif;
      color: #a5a2a2;
      font-size: 14px;
      line-height: 1;
      font-weight: 400;
      span {
        margin-top: 0rem;
        margin-bottom: 1.6rem;
        font-family: Karla, sans-serif;
        font-size: 1.25rem;
        line-height: 1;
        font-weight: 400;
        text-align: right;
        display: block;
        color: #333;
      }
    }
  `,
  LotText: styled.div`
    opacity: 1;
    font-family: Karla, sans-serif;
    color: #4c4949;
    font-size: 14px;
    line-height: 0.85rem;
    font-weight: 400;
  `,
  LotTextLink: styled.a`
    opacity: 1;
    font-family: Karla, sans-serif;
    color: #4c4949;
    font-size: 14px;
    line-height: 0.85rem;
    font-weight: 400;    
    text-decoration: none;
  `,
};

export default function PackageCard({
  image,
  title,
  className,
  subtitle,
  handleView,
  bed,
  bath,
  car,
  area,
  frontage = 0,
  price,
  pdf,
  house,
  height = "150px",
  hideView = false,
  onCloseButtonClick = () => {},
  land = 0,
  lotNumber,
  status,
  builderName,
  builderLogo,
  showViewLotLink,
  mediaType,
  mediaId,
  onViewLotLinkClickHandler = () => {},
}) {
  const dispatch = useDispatch();
  // const favouritePackages = useSelector(getFavouritePackages)

  const brochureClickHandler = (e) => {
    e.preventDefault();
  };

  return (
    <S.Card className={className}>
      <S.CardContent>
        <S.CardMediaWrapper>
          <S.CardMedia
            component="img"
            alt=""
            image={image || "./images/h-l-img.jpg"}
          />
        </S.CardMediaWrapper>
        {builderLogo && <S.BuilderLogo src={builderLogo} />}
      </S.CardContent>
      <S.CardMainContent>
        <S.CardMainContentLeft>
          <h2>{title}</h2>
          <h3>{builderName}</h3>

          <div className="size">
            <div className="img-wrapper house">
              <img src="./images/house-hollow.png" className="houseIcon" />
            </div>
            <div>
              House Size{" "}
              <span>
                {area}sq
              </span>
            </div>
          </div>
          {lotNumber ? (
            <>
            <div className="size">
              <div className="img-wrapper">
                <img src="./images/area-icon.png" className="areaIcon" />
              </div>
              <div>
                Land Size{" "}
                <span>
                  {land}m<sup>2</sup>
                </span>
              </div>
            </div>

            <div className="size">
              <div className="img-wrapper">
                <img src="./images/size.svg" className="frontageIcon" />
              </div>
              <div>
                Frontage{" "}
                <span>
                  {frontage}m
                </span>
              </div>
            </div>
            </>
          ) : (
            <div className="size">
              <div className="img-wrapper showcase">
                <img src="./images/info-italic.svg" />
              </div>
              <div>Showcase</div>
            </div>
          )}
        </S.CardMainContentLeft>
        <S.CardMainContentRight>
          <h3 className="price">
            { !house.fixedPrice && 'From' } <span>{formatPrice(price)}</span>
          </h3>
          <S.CardMainContentAmenities>
            <div className="amenities-item">
              <img src="./images/bed.svg" alt="" />
              <div>{bed}</div>
            </div>
            <div className="amenities-item">
              <img src="./images/bath.svg" alt="" />
              <div>{bath}</div>
            </div>
            <div className="amenities-item">
              <img src="./images/car.svg" alt="" />
              <div>{car}</div>
            </div>
          </S.CardMainContentAmenities>
          {house.lot && (
            <S.LotButton status={status}>
              <S.Status status={status} />
              {showViewLotLink ? (
                <S.LotTextLink
                  onClick={() => onViewLotLinkClickHandler(house.lot)}
                >
                  Lot {lotNumber}
                </S.LotTextLink>
              ) : (
                <S.LotText>Lot {lotNumber}</S.LotText>
              )}
            </S.LotButton>
          )}          
          {pdf && (
            <S.LotButton>
              <S.LotTextLink href={pdf} target="_blank">
                Brochure
              </S.LotTextLink>
            </S.LotButton>
          )}
        </S.CardMainContentRight>
      </S.CardMainContent>
      <S.Enquire onClick={handleView}>
        <div>Enquire</div>
      </S.Enquire>
    </S.Card>
  );
}
