import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import CustomSlider from "./common/CustomSlider";
import PropertyModal from "./common/PropertyModal";
import PackageCard from "./PackageCard";
import {
  getHouses,
  getBuildersSelectData,
  getUnsoldHouses,
  getUnsoldLots,
  getHousesSortBy,
  getProject
} from "../redux/selectors";

import {
  filterHouses,
  clearFilterHouses,
  sortHouses,
} from "../redux/rootReducer";
import { formatPrice } from "../util";

import CustomSelect from "./common/CustomSelect";

const S = {
  Container: styled.div`
    margin: 2.5rem auto 0rem;
    padding: 5rem 1rem;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-self: stretch;
    @media screen and (max-width: 500px) {
      margin: 0 !important;
      padding: 1rem !important;
    }
  `, 
  Header: styled.div`
    h1 {
      margin-bottom: 0px;
      color: #fff;
      font-size: 2rem;
      font-weight: 600;
      text-align: center;
      @media screen and (max-width: 479px) {
        font-size: 1.5rem;
      }
    }

    p {
      max-width: 40rem;
      margin-right: auto;
      margin-left: auto;
      padding-top: 1rem;
      font-family: Karla, sans-serif;
      color: #fff;
      font-size: 0.9rem;
      text-align: center;
      @media screen and (max-width: 479px) {
        font-size: 0.8rem;
      }
    }
  `,
  ButtonSection: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  ButtonsWrapper: styled.div`
    position: relative;
    display: flex;
    width: 75%;
    height: 2.2rem;
    justify-content: flex-end;
    border-style: solid;
    border-width: 1px;
    border-color: #f0f0f0;
    border-radius: 17px;
    background-color: #f5faff;
  `,
  Button: styled.div`
    position: relative;
    display: flex;
    width: 50%;
    height: 2.3rem;
    margin-top: -0.2rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `,
  ButtonText: styled.div`
    margin-top: 3px;
    font-family: Karla, sans-serif;
    color: #443f3f;
    font-size: 0.85rem;
    line-height: 1;
    font-weight: 400;
  `,
  ButtonTextSelected: styled.div`
    margin-top: 3px;
    font-family: Karla, sans-serif;
    font-size: 0.85rem;
    line-height: 1;
    font-weight: 400;
  `,
  Filters: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  CustomSelect: styled(CustomSelect)`
    margin-bottom: 2.5rem;
  `,
  SortClear: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  ButtonSelected: styled.div`
    position: absolute;
    display: flex;
    width: 50%;
    height: 2.3rem;
    margin-top: -0.2rem;
    justify-content: center;
    align-items: center;
    border-style: none;
    border-width: 1px;
    border-color: #f0f0f0;
    border-radius: 17px;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
    ${({ selected }) =>
      selected === "landForSale" &&
      css`
        left: 0;
      `}
  `,
  FilterSection: styled.div`
    max-width: 90rem !important;
    margin-left: auto;
    margin-right: auto;
  `,
  FilterContainer: styled.div`
    margin-left: 1rem;
    margin-right: 1rem;
    min-width: 90%;
  `,
  Empty: styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: auto;
    min-height: 100vh;
    padding-top: 5rem;
    padding-left: 5rem;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-image: linear-gradient(100deg, rgba(0, 0, 0, 0.86), rgba(0, 0, 0, 0.66) 52%, hsla(0, 0%, 100%, 0)), url("https://assets.website-files.com/60e27686ae9d78da36894364/60e27dfb43b07d4e243b3e02_Halcyon-E01_Exterior_Lot_12_Corner_Lower-p-1080.jpeg");
    background-position: 0px 0px, 50% 50%;
    background-size: auto, cover;
    background-repeat: repeat, no-repeat;

    @media screen and (max-width: 991px) {
      padding-right: 2.5rem;
      padding-left: 2.5rem;
      max-width: initial;
      width: initial;
    }
    @media screen and (max-width: 479px) {
      padding-right: 1rem;
      padding-left: 1rem;
      /*
      margin-left: -1rem;
      margin-right: -1rem;
      */
    }
  }
  `,
  SliderContainer: styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 2.5rem;
    margin-bottom: 0rem;
    -webkit-justify-content: space-between;
    -ms-flex-pack: distribute;
    justify-content: space-between;

    @media screen and (min-width: 1440px) {
      -webkit-justify-content: space-between;
      -ms-flex-pack: distribute;
      justify-content: space-between;
    }
    @media screen and (max-width: 1100px) {
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      text-align: center;
    }
    @media screen and (max-width: 500px) {
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      flex-direction: column;
    }
  `,
  Slider: styled(CustomSlider)`
    width: 444px;
    padding-left: 1rem;
    padding-right: 1rem;

    @media screen and (max-width: 991px) {
      margin-left: auto;
      margin-right: auto;
    }
    @media screen and (max-width: 500px) {
      width: 100%;      
    }
  `,
  PackagesContainer: styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 90rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-left: auto;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  `,
  CardsContainer: styled.div`
    height: 100%;
    padding-top: 0rem;
    padding-left: 1px;
    padding-right: 1px;

    .package-card:last-child {
      margin-bottom: 2rem;
    }
  `,
  OverflowContainer: styled.div`
    overflow: auto;
  `,
  FilterBtn: styled.div`
    flex: 0 auto;
    cursor: pointer;
    max-width: 100%;
    display: inline-block;
    background-color: transparent;

    img {
      width: 1.5rem;
      height: 1.5rem;
      opacity: 0.65;
      max-width: 100%;
      vertical-align: middle;
      display: inline-block;
      border: 0;
    }
  `,
  ClearFilterBtn: styled.div({
    fontFamily: "Karla, sans-serif",
    color: "white",
    fontWeight: "400",
    display: "flex",
    position: "relative",
    overflow: "hidden",
    width: "31%",
    cursor: "pointer",
    alignItems: "center",
    height: "66px",
    a: {
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      lineHeight: 1.5,
      height: "2.6rem",
      color: "white",
      margin: 0,
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "#bae1f5",
      borderRadius: "100px",
      padding: "10px 12px 10px 12px",
      alignSelf: "flex-end",
      width: "100%",
    },
  }),
};
const Homes = () => {
  const dispatch = useDispatch();
  const [enquireFormParams, setEnquireFormParams] = useState();
  const project = useSelector(getProject);
  const houses = useSelector(getHouses);
  const housesSortBy = useSelector(getHousesSortBy);
  const houseFilters = useSelector((state) => state.houses.filters);

  const buildersSelectData = useSelector(getBuildersSelectData);
  const unsoldHouses = useSelector(getUnsoldHouses);
  const unsoldLots = useSelector(getUnsoldLots);
  const [calculated, setCalculated] = useState(false);
  const [packageData, setPackageData] = useState({});

  const maxBeds = unsoldHouses
    .map((house) => house.houseType.bed)
    .reduce((max, next) => (next > max ? next : max), 0);
  const maxBaths = unsoldHouses
    .map((house) => house.houseType.bath)
    .reduce((max, next) => (next > max ? next : max), 0);

  if (!calculated && unsoldHouses.length && unsoldLots.length) {
    const areaMinPackageRaw =
      unsoldHouses
        .map((house) => house.houseType.area)
        .reduce((min, next) => (next < min ? next : min), 99999999) || 0
    const areaMinPackage = Math.round(Math.floor(areaMinPackageRaw / 1) * 1)

    const areaMaxPackageRaw = unsoldHouses
      .map((house) => house.houseType.area)
      .reduce((max, next) => (next > max ? next : max), 0)
    const areaMaxPackage = Math.round(Math.ceil(areaMaxPackageRaw / 1) * 1)

    // package price MIN MAX
    const priceMinPackageRaw = Math.min(...unsoldHouses.map((house) => house.price))
    const priceMinPackage = Math.floor(priceMinPackageRaw / 10000) * 10000
    const priceMaxPackageRaw = Math.max(...unsoldHouses.map((house) => house.price))
    const priceMaxPackage = Math.ceil(priceMaxPackageRaw / 10000) * 10000

    setPackageData({
      areaMin: areaMinPackage,
      areaMax: areaMaxPackage,
      priceMin: priceMinPackage,
      priceMax: priceMaxPackage,
    });
    setCalculated(true);
  }

  const handleHouseView = (house) => {
    setEnquireFormParams({ lotId: house.lot?.id, houseId: house.id });
  };

  const valuetext = (value) => {
    return `${value}m`;
  };

  const onViewLotLinkClickHandler = (lot) => {
    // dispatch(showLotPopup(lot))
  };
  if (unsoldHouses?.length === 0)
    return (
      <S.Empty className="emptyContainer">
        <PropertyModal
          title={`${project.frontendName} is currently sold out of house & land packages.`}
          subtitle="New house & land options are expected to be released shortly. Please register your details below so we can keep you informed."
          handleClose={() => setEnquireFormParams()}
          hideBackButton={true}
          disableModalMode={true}
          type="registration"
        />
      </S.Empty>
    );
  return (
    <S.Container className="homesContainer">
      <S.FilterSection className="FilterSection">
        <S.SliderContainer className="SliderContainer">
          <S.Slider
            className="Slider"
            label="Price"
            marks={[
              {
                value: packageData.priceMin,
                label: formatPrice(packageData.priceMin),
              },
              {
                value: packageData.priceMax,
                label: formatPrice(packageData.priceMax),
              },
            ]}

            valueLabelFormat={formatPrice}
            valuetext={valuetext}
            value={
              houseFilters.price.min === undefined
                ? [packageData.priceMin, packageData.priceMax]
                : [houseFilters.price.min, houseFilters.price.max]
            }
            min={packageData.priceMin}
            max={packageData.priceMax}
            step={10000}
            handleValueChange={(e, filterData) =>
              dispatch(filterHouses({ filterData, filterType: "price" }))
            }
          />
          <S.Slider
            className="Slider"
            label="House Size (sq)"
            marks={[
              { value: packageData.areaMin, label: (packageData.areaMin || 0) + "sq" },
              { value: packageData.areaMax, label: (packageData.areaMax || 0) + "sq" },
            ]}
            valueLabelFormat={(value = 0) => value + "sq"}
            valuetext={valuetext}
            value={
              houseFilters.area.min === undefined
                ? [packageData.areaMin, packageData.areaMax]
                : [houseFilters.area.min, houseFilters.area.max]
            }
            min={packageData.areaMin}
            max={packageData.areaMax}
            step={1}
            handleValueChange={(e, filterData) =>
              dispatch(filterHouses({ filterData, filterType: "area" }))
            }
          />
        </S.SliderContainer>
        <S.FilterContainer className="FilterContainer">
          <S.Filters className="Filters">
            <CustomSelect
              dataGenerate={maxBeds}
              multiple={true}
              onAction={(filterData) =>
                dispatch(filterHouses({ filterData, filterType: "bedrooms" }))
              }
              value={houseFilters.bedrooms}
              emptyLabel="Bedrooms"
            ></CustomSelect>
            <CustomSelect
              dataGenerate={maxBaths}
              dataGenerateStep={0.5}
              multiple={true}
              onAction={(filterData) =>
                dispatch(filterHouses({ filterData, filterType: "bathrooms" }))
              }
              value={houseFilters.bathrooms}
              emptyLabel="Bathrooms"
            ></CustomSelect>
            <CustomSelect
              data={buildersSelectData}
              multiple={true}
              onAction={(filterData) =>
                dispatch(filterHouses({ filterData, filterType: "builders" }))
              }
              value={houseFilters.builders}
              emptyLabel="Builders"
            ></CustomSelect>
          </S.Filters>
          <S.SortClear className="SortClear">
            <S.CustomSelect
              className="CustomSelect"
              data={[
                {
                  label: "Price: Low to High",
                  value: "price-asc",
                },
                {
                  label: "Price: High to Low",
                  value: "price-desc",
                },
                {
                  label: "Area: Low to High",
                  value: "area-asc",
                },
                {
                  label: "Area: High to Low",
                  value: "area-desc",
                },
              ]}
              emptyLabel="Sort By"
              value={housesSortBy}
              onAction={(value) => dispatch(sortHouses({ sortBy: value }))}
            ></S.CustomSelect>
            <S.ClearFilterBtn className="ClearFilterBtn">
              <a onClick={() => dispatch(clearFilterHouses())}>Clear Filters</a>
            </S.ClearFilterBtn>
          </S.SortClear>
        </S.FilterContainer>
      </S.FilterSection>
      <S.PackagesContainer className="PackagesContainer">
        {houses.map((house) => (
          <PackageCard
            key={house.id}
            className="package-card"
            house={house}
            title={house.houseType.name}
            subtitle={house.houseType.builder}
            handleView={() => handleHouseView(house)}
            builderName={
              house.houseType.builder ? house.houseType.builder.name : "-"
            }
            builderLogo={house.houseType?.builder?.thumbnailUrl}
            bed={house.houseType.bed}
            bath={house.houseType.bath}
            car={house.houseType.car}
            area={house.houseType.area}
            land={(house.lot || {}).sqm}
            frontage={(house.lot || {}).dimensions?.width}
            price={house.price}
            fixedPrice={house.fixedPrice ?? false}
            image={house?.facade?.url}
            pdf={house?.pdf?.url || house?.houseType?.pdf?.url}
            status={(house.lot || {}).status}
            lotNumber={(house.lot || {}).humanId}
            showViewLotLink
            onViewLotLinkClickHandler={onViewLotLinkClickHandler}
            mediaType="package"
            mediaId={house.id}
          />
        ))}
      </S.PackagesContainer>
      {enquireFormParams && (
        <PropertyModal
          lotId={enquireFormParams.lotId}
          packageId={enquireFormParams.houseId}
          handleClose={() => setEnquireFormParams()}
        />
      )}
    </S.Container>
  );
};

export default Homes;
