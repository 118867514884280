import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled, { css } from "styled-components";

const S = {
  Header: styled.div`
    h1 {
      margin-bottom: 0px;
      color: #fff;
      font-size: 2rem;
      font-weight: 600;
      text-align: center;
    }

    p {
      max-width: 40rem;
      margin-right: auto;
      margin-left: auto;
      padding-top: 1rem;
      font-family: Karla, sans-serif;
      color: #fff;
      font-size: 0.9rem;
      text-align: center;
    }
  `,
};
const Register = () => {
  return (
    <>
      <S.Header>
        <h1>Registration</h1>
        <p>
          Some registration copy. -- At Whiterock we’ve partnered with some of
          Australia’s leading luxury home builders to deliver outstanding value
          house and land packages. Below you can select from a range of features
          including land size, bedrooms and builder to find the perfect package
          for you.
        </p>
      </S.Header>
    </>
  );
};

export default Register;
