import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";

const S = {
  Header: styled.div`
    margin-top: -5.5rem;
    h1 {
      margin-bottom: 1rem;
      color: #fff;
      font-size: 2rem;
      font-weight: 600;
      text-align: center;
    }

    p {
      max-width: 40rem;
      margin-right: auto;
      margin-left: auto;
      padding-top: 1rem;
      font-family: Karla, sans-serif;
      color: #fff;
      font-size: 0.9rem;
      text-align: center;
    }
  `,
  Land: styled.iframe`
    margin-left: -1rem;
    margin-bottom: -5.5rem;
    width: 100vw;
    flex: 1;
    align-self: stretch;
    border-width: 0;
  `,
};
const Land = () => {
  return (
    <>
      <S.Header>
        <h1>Land</h1>
      </S.Header>
      <S.Land src="https://intrapac-staging.herokuapp.com/embedded" />
    </>
  );
};

export default Land;
