import axios from "axios";

const baseUrl = (state) =>
  `${process.env.REACT_APP_API_BASE_URL}api/projects/${
    process.env.REACT_APP_OVERRIDE_PROJECT_ID || state.project?.id
  }/`;

const api = {
  fetchProjectDetails: () => async (dispatch, getState) => {
    let host = window.location.host;
    const urlParams = new URLSearchParams(window.location.search);
    const projectId = urlParams.get("project");
    let url = `${process.env.REACT_APP_API_BASE_URL}api/projects/?hostname=${host}`;
    if (process.env.REACT_APP_OVERRIDE_PROJECT_ID)
      url = baseUrl(getState())
    else if (projectId)
      url = `${process.env.REACT_APP_API_BASE_URL}api/projects/${projectId}`

    return await axios.get(url);
  },
  fetchLots: () => async (dispatch, getState) => {
    return await axios.get(baseUrl(getState()) + "lots-geo");
  },
  fetchStages: () => async (dispatch, getState) => {
    return await axios.get(baseUrl(getState()) + "stages");
  },
  fetchHouses: () => async (dispatch, getState) => {
    return await axios.get(baseUrl(getState()) + "packages-enabled");
  },
  fetchBuilders: () => async (dispatch, getState) => {
    return await axios.get(baseUrl(getState()) + "builder");
  },
  postEnquireForm: (data) => async (dispatch, getState) => {
    const {
      email,
      firstName,
      mobilePhone,
      postcode,
      lastName,
      howDidYouHearAboutUs,
      packageId,
      lotId,
      captchaToken
    } = data;
    return await axios.post(baseUrl(getState()) + "enquire", {
      email,
      firstName,
      lastName,
      mobilePhone,
      postcode,
      howDidYouHearAboutUs,
      lotId,
      packageId,
      captchaToken
    });
  },
  postRegistrationForm: (data) => async (dispatch, getState) => {
    const {
      email,
      firstName,
      mobilePhone,
      postcode,
      lastName,
      howDidYouHearAboutUs,
      captchaToken
    } = data
    return axios
      .post(baseUrl(getState()) + 'register', {
        email,
        firstName,
        lastName,
        mobilePhone,
        postCode: postcode,
        howDidYouHearAboutUs,
        skipRedirect: true,
        captchaToken
      })
  },    
};
export default api;
