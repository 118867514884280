import React, { useState } from "react";
import styled from "styled-components";
import { ExpandMoreOutlined, KeyboardArrowDownSharp } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
      color: "white",
    },
  },
  input: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #bae1f5",
    borderRadius: "100px",
    fontSize: "0.85rem",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    color: "black",
    fontFamily: "Karla, sans-serif",
    fontWeight: "400",
    "&:focus": {
      borderColor: "#80bdff",
      borderRadius: "100px",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      backgroundColor: theme.palette.background.paper,
      color: "black",
    },
  },
}))(InputBase);

const CustonInputLabel = withStyles((theme) => ({
  root: {
    color: "white !important",
  },
  focused: {
    color: "white !important",
  },
}))(InputLabel);

const S = {
  Wrapper: styled.div`
    position: "relative";
    width: 31%;
    &&& {
      .MuiFormControl-root {
        height: 65px;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
      }
    }
  `,
};

export default function CustomSelect({
  data = [],
  emptyLabel,
  onAction,
  value: controlledValue,
  dataGenerate,
  dataGenerateStep = 1,
  multiple,
}) {
  const defaultValue = multiple ? [] : "";
  const value = controlledValue === undefined ? defaultValue : controlledValue;

  const handleChange = (event) => {
    onAction(event.target.value);
  };

  const fillArray = () => {
    let d = [];
    for (let i = 1; dataGenerate && i <= dataGenerate; i+= dataGenerateStep) {
      d.push({ value: i, label: i });
    }
    return d;
  };

  const items = [...data, ...fillArray()];
  const menuItems = [];
  const labelsForKey = {};
  items.forEach((item) => {
    labelsForKey[item.value] = item.label;
    const selected =
      multiple && !!value?.length && value.indexOf(item.value) >= 0;
    menuItems.push(
      <MenuItem value={item.value} key={item.value}>
        {multiple ? (
          <Checkbox
            checked={selected}
            color="default"
            disableRipple={true}
            size="small"
          />
        ) : null}
        {item.label}
      </MenuItem>
    );
  });

  const renderValue = (value) => {
    if (value === "" || (Array.isArray(value) && !value.length))
      return emptyLabel;
    if (Array.isArray(value)) {
      const sortAlphaNum = (a, b) =>
        `${a}`.localeCompare(b, "en", { numeric: true });
      const labels = value.map((key) => labelsForKey[key]);
      labels.sort(sortAlphaNum);
      return labels.join(", ");
    }
    return labelsForKey[value];
  };
  return (
    <S.Wrapper>
      <FormControl>
        {value !== defaultValue || value?.length ? (
          <CustonInputLabel id="select-sort-label" shrink>
            {emptyLabel || data[0]?.label}
          </CustonInputLabel>
        ) : undefined}

        <Select
          labelId="select-sort-label"
          id="select-sort-label"
          value={value}
          onChange={handleChange}
          input={<BootstrapInput />}
          IconComponent={ExpandMoreOutlined}
          multiple={multiple}
          variant="filled"
          displayEmpty={!!emptyLabel}
          renderValue={emptyLabel ? renderValue : undefined}
        >
          {menuItems}
        </Select>
      </FormControl>
    </S.Wrapper>
  );
}
