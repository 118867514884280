import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import TagManager from 'react-gtm-module'
import {
  fetchProjectDetails,
  fetchLots,
  fetchHouses,
  fetchStages,
  fetchBuilders,
} from "../src/redux/rootReducer";
import { getProject } from "./redux/selectors";
import Homes from "./components/Homes";
import Register from "./components/Register";
import Land from "./components/Land";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

const S = {
  App: styled.div`
    margin: 0;
    font-family: Arial, sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #333;
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    display: flex;
    flex-direction: column;
  `,
  Content: styled.div`
    width: 100%;
    /*
    margin: 2.5rem auto 0rem;
    padding: 5rem 1rem;
    */
    display: flex;
    flex-direction: column;
    flex: 1;
    align-self: stretch;
    @media screen and (max-width: 500px) {
      /*
      margin: 1rem auto 0rem !important;
      padding: 1rem 1rem !important;
      */
    }
  `,
};

function App() {
  const dispatch = useDispatch();
  const [bodyHeight, setBodyHeight] = useState();
  const [loaded, setLoaded] = useState();
  const [minHeight, setMinHeight] = useState("600px");
  const project = useSelector(getProject);
  const updateHeight = () => {
    var height = document.getElementsByTagName("html")[0].scrollHeight;
    setBodyHeight(height);
  };
  useEffect(() => {
    window.addEventListener(
      "message",
      function (e) {
        var eventName = e.data[0];
        var data = e.data[1];
        if (eventName === "setParentViewportHeight" && data) {
          setMinHeight(data + "px");
        }
      },
      false
    );
    window.parent.postMessage(["requestViewportHeight"], "*");
  }, []);
  
  useEffect(() => {
    async function init() {
      await dispatch(fetchProjectDetails());
      await dispatch(fetchLots());
      await dispatch(fetchHouses());
      dispatch(fetchStages());
      dispatch(fetchBuilders());
      setLoaded(true)
    }
    updateHeight();
    const intervalRef = setInterval(updateHeight, 1000);
    init();
    return function cleanup() {
      clearInterval(intervalRef);
    };
  }, [dispatch]);
  const marketingSiteCssUrl = project?.marketingSiteCssUrl;
  useEffect(() => {
    if (marketingSiteCssUrl) {
      var head = document.head;
      var link = document.createElement("link");
      link.type = "text/css";
      link.rel = "stylesheet";
      link.href = marketingSiteCssUrl;
      head.appendChild(link);
    }
  }, [marketingSiteCssUrl]);
  useEffect(() => {
    if (!project?.gTag)
      return;    
    try {
      console.log('*** init gTag', project?.gTag)
      TagManager.initialize({ gtmId: project?.gTag })
      console.log('*** finish init gTag')
    } catch (e) {
      console.log('*** error setting gTag', e)
    }
  }, [project?.gTag]);
  useEffect(() => {
    window.parent.postMessage(["setHeight", bodyHeight], "*");
    console.log("**** bodyHeight", bodyHeight)
  }, [bodyHeight]);
  return (
    <Router>
      {loaded && (
        <S.App className="App" style={{ minHeight }}>
          <S.Content className="Content">
              <Switch>
                <Route path="/register">
                  <Register />
                </Route>
                <Route path="/land">
                  <Land />
                </Route>
                <Route path="/">
                  <Homes />
                </Route>
              </Switch>
          </S.Content>
        </S.App>
      )}
    </Router>
  );
}

export default App;
