import { createSelector } from "reselect";

const formatStageName = stage => (stage?.name ? `${stage?.number} (${stage?.name})` : `${stage?.number}`)

export const getCachedPackages = (state) => state.cachedPackages;
export const getStages = (stage) =>
  stage.stages.data ? stage.stages.data : []; // .filter((stage) => stage.enabled)
export const getLotsFilters = (state) => state.lots.filters;
export const getLotsSortBy = (state) => state.lots.sortBy;
export const getLots = (state) =>
  state.lots.data
    .map((lot) => ({
      ...lot,
      sqm: lot.sqm ? lot.sqm : 0,
      price: lot.price ? lot.price : 0,
      dimensions: {
        ...lot?.dimensions,
        width: lot.dimensions?.width || 0,
      },
    }))
    .filter(
      (lot) =>
        (state.lots.filters.area.max === undefined || lot.sqm <= state.lots.filters.area.max) &&
        (state.lots.filters.area.min === undefined || lot.sqm >= state.lots.filters.area.min) &&
        (state.lots.filters.frontage.max === undefined ||
          lot.dimensions.width <= state.lots.filters.frontage.max) &&
        (state.lots.filters.frontage.min === undefined ||
          lot.dimensions.width >= state.lots.filters.frontage.min) &&
        (state.lots.filters.price.max === undefined || lot.price <= state.lots.filters.price.max) &&
        (state.lots.filters.price.min === undefined || lot.price >= state.lots.filters.price.min),
    )
    export const getAllHouses = (state) => state.houses.data;
    export const getLotsGroupedByStages = createSelector(
      getStages,
      getLots,
      getLotsSortBy,
      getAllHouses,
      (stages, lots, sortBy, allHouses) => {
        if (!stages.length || !lots.length) {
          return []
        }
        const dwellingPackages = (allHouses || []).filter(p => p.dwellingContract)
        const mappedStages = {}
        stages.forEach((stage) => {
          mappedStages[stage.id] = {
            ...stage,
            lotsData: [],
          }
        })
    
        lots
          .filter(
            (lot) => lot.status !== 'Sold' && lot.status !== 'Settled' && lot.status !== 'Deposited' 
            && (lot.status !== 'Unreleased' || mappedStages[lot.stage?.id]?.releaseSatus === 'COMING_SOON')
            && !dwellingPackages.some(p => p.lot?.id === lot.id),
          )
          .sort((a, b) => {
            let comparator
            let direction = 'desc'
    
            switch (sortBy) {
              case 'price-asc':
                comparator = 'price'
                direction = 'asc'
                break
              case 'price-desc':
                comparator = 'price'
                break
              case 'area-asc':
                comparator = 'sqm'
                direction = 'asc'
                break
              case 'area-desc':
                comparator = 'sqm'
                break
    
              default:
                comparator = null
            }
    
            if (!comparator) return
    
            if (a[comparator] > b[comparator]) return direction === 'desc' ? -1 : 1
            if (b[comparator] > a[comparator]) return direction === 'desc' ? 1 : -1
    
            return 0
          })
          .forEach((lot) => {
            if (lot.stage && mappedStages[lot.stage.id].lots.includes(lot.id)) {
              mappedStages[lot.stage.id].lotsData.push(lot)
            }
          })
    
        const result = []
    
        Object.keys(mappedStages).forEach((key) => {
          if (mappedStages[key]?.lots?.length) result.push(mappedStages[key])
        })
    
        result.sort((a, b) => {
          if (isNaN(a.displayPosition) || isNaN(b.displayPosition)) {
            const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
            const aDisplay = formatStageName(a)
            const bDisplay = formatStageName(b)
            return collator.compare(aDisplay, bDisplay)
          }
          return a.displayPosition - b.displayPosition
        })
        return result
      },
    )
export const getUnsoldHouses = (state) =>
  state.houses.data.filter(
    (house) =>
      house.lot?.status !== 'Unreleased' &&
      house.lot?.status !== 'Settled' &&
      house.lot?.status !== 'Sold',
  )

export const getUnsoldLots = (state) =>
  state.lots.data.filter(
    (lot) => lot.status !== 'Unreleased' && lot.status !== 'Settled' && lot.status !== 'Sold',
  )
export const getHousesSortBy = (state) => state.houses.sortBy
export const getHouses = (state) =>
  state.houses.data
    .filter(
      (house) =>
        house.lot?.status !== 'Unreleased' &&
        house.lot?.status !== 'Settled' &&
        house.lot?.status !== 'Sold' &&
        (state.houses.filters.area.max === undefined ||
          house.houseType.area <= state.houses.filters.area.max) &&
        (state.houses.filters.area.min === undefined ||
          house.houseType.area >= state.houses.filters.area.min) &&
        (state.houses.filters.price.max === undefined ||
          house.price <= state.houses.filters.price.max) &&
        (state.houses.filters.price.min === undefined ||
          house.price >= state.houses.filters.price.min),
    )
    .filter((house) => {
      let combinedFilter = true

      if (state.houses.filters.bedrooms?.length) {
        combinedFilter =
          combinedFilter && state.houses.filters.bedrooms.indexOf(house.houseType.bed) >= 0
      }

      if (state.houses.filters.bathrooms?.length) {
        combinedFilter =
          combinedFilter && state.houses.filters.bathrooms.indexOf(house.houseType.bath) >= 0
      }

      if (state.houses.filters.builders?.length) {
        combinedFilter =
          combinedFilter && state.houses.filters.builders.indexOf(house.houseType.builder.id) >= 0
      }

      return combinedFilter
    })
    .sort((a, b) => {
      let comparator
      let direction = 'desc'

      switch (state.houses.sortBy) {
        case 'bathrooms':
          comparator = 'bath'
          break
        case 'bedrooms':
          comparator = 'bed'
          break
        case 'cars':
          comparator = 'car'
          break
        case 'price-asc':
          comparator = 'price'
          direction = 'asc'
          break
        case 'price-desc':
          comparator = 'price'
          break
        case 'area-asc':
          comparator = 'area'
          direction = 'asc'
          break
        case 'area-desc':
          comparator = 'area'
          break
        case 'lot-area-asc':
          comparator = 'lot-area'
          direction = 'asc'
          break
        case 'lot-area-desc':
          comparator = 'lot-area'
          break
        default:
          comparator = null
      }

      if (!comparator) return
      if (comparator === 'lot-area') {
        if (a.lot?.sqm > b.lot?.sqm) return direction === 'desc' ? -1 : 1
        if (b.lot?.sqm > a.lot?.sqm) return direction === 'desc' ? 1 : -1
      } else if (['price-asc', 'price-desc'].includes(state.houses.sortBy)) {
        if (a[comparator] > b[comparator]) return direction === 'desc' ? -1 : 1
        if (b[comparator] > a[comparator]) return direction === 'desc' ? 1 : -1
      } else {
        if (a.houseType[comparator] > b.houseType[comparator]) return direction === 'desc' ? -1 : 1
        if (b.houseType[comparator] > a.houseType[comparator]) return direction === 'desc' ? 1 : -1
      }

      return 0
    })
export const getBuildersSelectData = (state) => {
    const builders = [];

    state.builders.data.forEach((builder) => {
        if (state.houses.data.filter((house) => house.houseType.builder.id === builder.id).length) {
            builders.push({
                label: builder.name,
                value: builder.id,
            });
        }
    });

    return builders;
};

export const emailError = (state) => state.emailError;
export const getProject = (state) => state.project;
