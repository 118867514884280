import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import ReCAPTCHA from "react-google-recaptcha"
import TagManager from 'react-gtm-module'
import Modal from "@material-ui/core/Modal";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import Status from "./Status";
import { emailValidator } from "../../util";
import { postEnquireForm, postRegistrationForm } from "../../redux/rootReducer";
import { TextField, Select, MenuItem } from "@material-ui/core";
import BackButton from "./BackButton";
import { getProject } from "../../redux/selectors";

const S = {
  Card: styled(Card)`
    &&& {
      .MuiCardContent-root {
        padding: 0;
      }
      margin-top: 3rem;
      padding: 3rem 2rem;
      height: auto;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0 8px 16px -2px rgba(0, 0, 0, 0.15);
      max-width: 25rem;
      margin-left: auto;
      margin-right: auto;

      p.toast {
        font-family: Karla, sans-serif;
        font-size: 1rem;
        font-weight: 300;
        color: red;
        margin-top: 1.5rem;
        margin-bottom: -1rem;
      }
    }
  `,
  Success: styled.h2`
    &.regular {
      font-family: Karla, sans-serif;
      font-size: 1rem;
      font-weight: 300;
      margin-top: -1rem;
      margin-bottom: 2rem;
    }
    &.registration {
      font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
      color: #fff;
      font-size: 1.9rem;
      line-height: 1.1;
      font-weight: 700;          
    }
  `,  
  LotHeader: styled.div`
    font-size: 16px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  LotStatus: styled.div`
    display: flex;
    margin: 20px 0px 10px 0px;
  `,
  FavoriteIcon: styled(FavoriteIcon)`
    color: rgb(243, 104, 39);
  `,
  FavoriteBorderIcon: styled(FavoriteBorderIcon)`
    color: rgb(243, 104, 39);
  `,
  StatusContainer: styled.div`
    display: flex;
    align-items: center;
    margin-right: 20px;
  `,
  FavoriteIconContainer: styled.div`
    display: flex;
    align-items: center;

    .MuiSvgIcon-root {
      height: 20px;
    }
  `,
  Status: styled(Status)`
    margin-right: 5px;
  `,
  LotDetails: styled.div`
    display: flex;
    justify-content: space-between;
    p {
      color: #c7c7c7;
      display: inline;
    }
  `,
  Form: styled.form`
    display: flex;
    flex-direction: column;
    &.regular {
      .MuiInput-underline:before {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }
      h2 {
        margin-top: 1rem;
        margin-bottom: 0rem;
        font-family: Freighttext, Georgia, sans-serif;
        font-weight: 900;
        font-size: 1.5rem;
        line-height: 1;
      }


      input {
        margin-top: 1.5rem;
        padding-bottom: 0.75rem;
        padding-right: 0px;
        padding-left: 0px;
        border-style: none none solid;
        border-width: 1px;
        border-color: #000 #000 #e7e7e7;
        font-size: 0.85rem;
        color: #333;

        &::placeholder {
          color: #333;
          opacity: 1;
        }
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type=number] {
        -moz-appearance: textfield;
      }      

      select {
        margin-top: 1.5rem;
        padding-bottom: 0.75rem;
        padding-right: 0px;
        padding-left: 0px;
        border-style: none none solid;
        border-width: 1px;
        border-color: #000 #000 #c4c4c4;
        font-size: 0.85rem;
        color: #333;
        
        &::placeholder {
          color: #333;
          opacity: 1;
        }        
      }
      select:focus {
        color: #333;
        outline: none !important;
      }      
      .MuiSelect-root {
        margin-top: 1.5rem;
        padding-bottom: 0.75rem;
        padding-right: 0px;
        padding-left: 0px;
        border-style: none none solid;
        border-width: 1px;
        border-color: #000 #000 #c4c4c4;
        font-size: 0.85rem;
        color: #333;
      }
      .MuiSelect-icon {
        color: #c4c4c4;
        top: calc(50% - 3px);
      }
    }
    
    &.registration {
      display: flex;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      width: 50%;
      max-width: 25rem;

      .MuiInput-underline:after,
      .MuiInput-underline:before,
      .MuiInput-underline:hover:before,
      .MuiInput-underline:hover:after {
        border: none;
      }
      h2 {
        font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
        color: #fff;
        font-size: 1.9rem;
        line-height: 1.1;
        font-weight: 700;
      }

      p.soldOut {
        font-size: 0.9rem;
        margin-bottom: 1rem;
        margin-top: 1rem;
        font-family: 'Droid Sans', sans-serif;
        color: #fff;
      }

      p.toast {
        font-size: 0.9rem;
        margin-bottom: 1rem;
        margin-top: 1rem;
        font-family: 'Droid Sans', sans-serif;
        color: #fff;
      }

      input {
        width: 100%;
        margin-bottom: 1.5rem;
        padding-left: 0px;
        border-style: none none solid;
        border-width: 1px;
        border-color: #000 #000 #fff;
        background-color: transparent !important;
        color: #fff;
        font-size: 1rem;
      }

      input::placeholder {
        color: #fff !important;
        opacity: 1;      
      }
      
      .MuiSelect-root {
        display: block;
        height: 30px;
        padding: 8px 12px;
        padding-bottom: 0px;
        margin-bottom: 10px;
        line-height: 1.42857143;
        vertical-align: middle;
        border: 1px solid #cccccc;
        text-transform: none;

        width: 100%;
        padding-left: 0px;
        border-style: none none solid;
        border-width: 1px;
        border-color: #000 #000 #fff;
        background-color: transparent;
        color: #fff;
        font-size: 1rem;    
      }
      .MuiSelect-icon {
        color: #fff;
      }
      
      @media screen and (max-width: 991px) {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
      }
      @media screen and (max-width: 479px) {
        h2 {
          font-size: 1.5rem;
        }      
        p.soldOut {
          font-size: 14px;
        }
      }      
    }
  `,
  Enquire: styled.div`
    &.regular {
      width: 100%;
      margin-top: 2.5rem;
      margin-bottom: 2rem;
      border-radius: 100px;
      background-color: #ffb899;
      box-shadow: 7px 7px 20px -10px rgba(0, 0, 0, 0.33);
      font-size: 0.9rem;
      cursor: pointer;
      display: inline-block;
      padding: 9px 15px;
      color: white;
      border: 0;
      line-height: inherit;
      text-decoration: none;
      text-align: center;
      ${({ isDisabled }) =>
      isDisabled &&
        css`
          cursor: initial;
          background: #d3d3cf;
        `}  
    }

    &.registration {
      margin-top: 1.5rem;
      margin-left: 0rem;
      padding: 0.5rem 1.5rem;
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
      border-style: solid;
      border-width: 1px;
      border-color: #fff;
      background-color: transparent;
      color: #fff;
      font-weight: 700;
      text-decoration: none;

      border-color: #fff;
      color: #fff;

      width: 30%;
      margin-top: 0rem;
      margin-left: 0rem;
      -webkit-align-self: flex-end;
      -ms-flex-item-align: end;
      align-self: flex-end;

      text-align: center;

      width: 100%;
      margin-top: 1rem;    
      cursor: pointer;

      @media screen and (max-width: 991px) {
        width: 100%;
        margin-top: 2rem;    
      }
    }
  `,
};

const PropertyModal = ({
  lotId,
  onClose,
  handleClose,
  handleSave,
  hideBackButton,
  type,
  packageId,
  title: titleProp,
  subtitle,
  disableModalMode
}) => {
  const dispatch = useDispatch();
  const project = useSelector(getProject);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobilePhone: "",
    postcode: "",
    howDidYouHearAboutUs: "-",
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const formRef = useRef();
  const recaptchaRef = useRef()
  useEffect(() => {
    window.parent.postMessage(["scrollToTop"], "*");
  }, [formRef]);
  const checkFieldsAreFilled = () => {
    let filled = false;
    if (
      formData.firstName &&
      formData.lastName &&
      formData.email &&
      formData.mobilePhone &&
      formData.postcode &&
      formData.howDidYouHearAboutUs &&
      formData.howDidYouHearAboutUs !== '-'
    ) {
      filled = true;
    }
    return filled;
  };
  const isBtnDisabled = () => {
    let isBtnDisabled = true;
    if (checkFieldsAreFilled() && emailValidator.test(formData.email)) {
      isBtnDisabled = false;
    } else {
      isBtnDisabled = true;
    }
    return setIsDisabled(isBtnDisabled);
  };

  const handleInputValueChange = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const displayToast = (body, type) => {
    setToastMessage({
      body,
      type,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (submitting)
      return;
    if (checkFieldsAreFilled() === false) {
      displayToast("All fields must be filled", "error");
    } else if (
      checkFieldsAreFilled() &&
      emailValidator.test(formData.email) === false
    ) {
      displayToast("Email must be a valid email", "error");
    } else {
      let captchaToken
      try {
        captchaToken = await recaptchaRef.current.executeAsync();
      } catch (e) {
        console.log("error obtaining token", e)
      }
      if (!captchaToken)
        return displayToast('Please complete the CAPTCHA challenge before progressing')

      const payload = {
        ...formData,
        packageId,
        lotId,
        captchaToken
      };
      const action = type === 'registration' ? postRegistrationForm : postEnquireForm
      setSubmitting(true)
      try {
        const r = await dispatch(action(payload));
        setSubmitting(false)
        if (r.payload?.data) {          
          if (project.gTag) {
            try {
              const tagManagerArgs = {
                dataLayer: {
                    event: 'HLAppFormSubmitted', // event to be picked up by GTM
                }
              }
    
              TagManager.dataLayer(tagManagerArgs);
            } catch (e) {
              console.log('*** error setting gTag', e)
            }
          } else {
            console.log('*** no gTag found for project', project.id)
          }
          if (project.reaTag) {
            try {
              console.log('*** track REA tag', )
              window.REA.track_conversion(project.reaTag)
              console.log('*** finish track REA')
            } catch (e) {
              console.log('*** error tracking REA', e)
            }
          } else {
            console.log('*** no REA tag found for project', project.id)
          }
        
          const urlParams = new URLSearchParams(window.location.search);
          const redirect = urlParams.get("redirect");
          if (redirect) {
            window.top.location.href = redirect;
          } else {
            displayToast(
              "Thank you for your interest. Your inquiry has been submitted successfully,",
              "success"
            );
          }
        } else {
          displayToast(
            "There was an error submitting your request. Please try again later.",
            "warning"
          );
        }
  
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          mobilePhone: "",
          postcode: "",
          howDidYouHearAboutUs: "-",
        });
      } catch (e) {
        setSubmitting(false)
        displayToast(
          "There was an error submitting your request. Please try again later.",
          "warning"
        );
      }
    }
  };

  useEffect(() => {
    isBtnDisabled();
  });

  const title = titleProp || (type === "favourites" ? "Save Favourites" : "Enquire");
  const saveText = type === "registration" ? "Register now" : (type === "favourites" ? "Send" : "Enquire");

  const FormContent = (
    <>
      {toastMessage?.type === "success" ? (
        <S.Success className={type === "registration" ? "registration" : "regular"}>{toastMessage.body}</S.Success>
      ) : (
        <S.Form ref={formRef} className={type === "registration" ? "registration" : "regular"}>
          <h2>{title}</h2>
          {subtitle && (<p className="soldOut">{subtitle}</p>)}
          <TextField
            placeholder="First name"
            name="firstName"
            onChange={handleInputValueChange}
            value={formData.firstName}
            // autoFocus={true}
          />
          <TextField
            placeholder="Last name"
            name="lastName"
            onChange={handleInputValueChange}
            value={formData.lastName}
          />
          <TextField
            placeholder="Email"
            name="email"
            onChange={handleInputValueChange}
            type="email"
            value={formData.email}
          />
          <TextField
            placeholder="Mobile phone"
            name="mobilePhone"
            onChange={handleInputValueChange}
            type="number"
            value={formData.mobilePhone}
          />
          <TextField
            placeholder="Postcode"
            name="postcode"
            onChange={handleInputValueChange}
            value={formData.postcode}
          />
            <Select
              id="howDidYouHearAboutUs"
              name="howDidYouHearAboutUs"            
              onChange={handleInputValueChange}
              value={formData.howDidYouHearAboutUs}
            >
              <MenuItem value="-">How Did you Hear About Us?</MenuItem>
              {(project?.frontendEnquirySources || []).map(({name, value}) => (
                <MenuItem value={value}>{name}</MenuItem>
              ))}
            </Select>        
          {toastMessage?.body && <p className="toast">{toastMessage.body}</p>}
          <S.Enquire 
            className={type === "registration" ? "registration" : "regular"}
            isDisabled={isDisabled || submitting} 
            onClick={handleSubmit}
          >
            {submitting ? 'Submitting...' : saveText}
          </S.Enquire>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            style={{ alignSelf: 'center', margin: 20 }}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          />
        </S.Form>
      )}

      {!hideBackButton && (<BackButton onClickHandler={handleClose}></BackButton>)}    
    </>
  )
  return disableModalMode ? (
    FormContent
  ) : (
    <Modal open={true} onClose={handleClose}>
      <S.Card>
        <CardContent>
          {FormContent}
        </CardContent>
      </S.Card>
    </Modal>
  );
};

export default PropertyModal;
