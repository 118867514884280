import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api";

const initialState = {
  project: {},
  lots: {
    data: [],
    sortBy: undefined,
    filters: {
      price: {
        min: undefined,
        max: undefined,
      },
      area: {
        min: undefined,
        max: undefined,
      },
      frontage: {
        min: undefined,
        max: undefined,
      },
    },
  },
  houses: {
    data: [],
    sortBy: undefined,
    filters: {
      price: {
        min: undefined,
        max: undefined,
      },
      area: {
        min: undefined,
        max: undefined,
      },
    },
  },
  stages: {
    data: [],
  },
  builders: {
    data: [],
  },
  cachedPackages: {},
};


export const fetchProjectDetails = createAsyncThunk(
  "project/fetchProjectDetails",
  async (_, { dispatch, rejectWithValue }) => {
    const response = await dispatch(api.fetchProjectDetails());
    const { data, error } = response;
    if (data)
      return { project: data };
    return rejectWithValue({ error: error || "Error project information" });
  }
);

export const fetchLots = createAsyncThunk(
  "lots/fetchLots",
  async (_, { dispatch, rejectWithValue }) => {
    const response = await dispatch(api.fetchLots());
    const { data, error } = response;
    if (Array.isArray(data.items)) return { lots: data.items };
    return rejectWithValue({ error: error || "Error fetching lots" });
  }
);

export const fetchHouses = createAsyncThunk(
  "lots/fetchHouses",
  async (_, { dispatch, rejectWithValue }) => {
    const response = await dispatch(api.fetchHouses());
    const { data, error } = response;
    if (Array.isArray(data.items)) return { houses: data.items };
    return rejectWithValue({ error: error || "Error fetching houses" });
  }
);

export const fetchStages = createAsyncThunk(
  "lots/fetchStages",
  async (_, { dispatch, rejectWithValue }) => {
    const response = await dispatch(api.fetchStages());
    const { data, error } = response;
    if (Array.isArray(data.items)) return { stages: data.items };
    return rejectWithValue({ error: error || "Error fetching stages" });
  }
);

export const fetchBuilders = createAsyncThunk(
  "lots/fetchBuilders",
  async (_, { dispatch, rejectWithValue }) => {
    const response = await dispatch(api.fetchBuilders());
    const { data, error } = response;
    if (Array.isArray(data)) return { builders: data };
    return rejectWithValue({ error: error || "Error fetching builders" });
  }
);

export const postEnquireForm = createAsyncThunk(
  "postEnquireForm",
  async (payload, { dispatch, rejectWithValue }) => {
    const response = await dispatch(api.postEnquireForm(payload));
    const { data, error } = response;
    if (data) return { data };
    return rejectWithValue({
      error:
        error || "Error submitting enquire request. Please try again later.",
    });
  }
);


export const postRegistrationForm = createAsyncThunk(
  "postRegistrationForm",
  async (payload, { dispatch, rejectWithValue }) => {
    const response = await dispatch(api.postRegistrationForm(payload));
    const { status, data, error } = response;
    if (status.toString().indexOf('20') === 0) 
      return { data };
    return rejectWithValue({
      error:
        error || "Error submitting registration. Please try again later.",
    });
  }
);

const slice = createSlice({
  name: "d",
  initialState,
  reducers: {
    filterHouses(state, { payload }) {
      let filterData =
        payload.filterType === "area" || payload.filterType === "price"
          ? {
              min: payload.filterData[0],
              max: payload.filterData[1],
            }
          : payload.filterData;
      state.houses.filters[payload.filterType] = filterData;
    },
    clearFilterHouses(state) {
      state.houses.filters = initialState.houses.filters;
    },
    sortHouses(state, { payload }) {
      state.houses.sortBy = payload.sortBy;
    },
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [fetchProjectDetails.fulfilled]: (state, action) => {
      const { project } = action.payload;
      state.project = project;
    },
    [fetchLots.fulfilled]: (state, action) => {
      const { lots } = action.payload;
      state.lots.data = lots;
    },
    [fetchHouses.fulfilled]: (state, action) => {
      const { houses } = action.payload;
      state.houses.data = houses;
    },
    [fetchStages.fulfilled]: (state, action) => {
      const { stages } = action.payload;
      state.stages.data = stages;
    },
    [fetchBuilders.fulfilled]: (state, action) => {
      const { builders } = action.payload;
      state.builders.data = builders;
    },
  },
});

export const { filterHouses, clearFilterHouses, sortHouses } = slice.actions;
export default slice.reducer;
